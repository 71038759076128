import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import ImageRenderer from '../ImageRenderer'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import SvgEl from './../SvgEl'
import LOGO from './../../svg/logos'

const EventsList = (props) => {

  const [jsonContent, setJsonContent] = useState('');
  var source_url = '';
  var newArr =[];
  function loadJson(url) {
  var _json = url;
  axios.get(_json)
  .then(function (response) {

    //console.log(response);


  var _jsonContent = response.data.data.map( (item, index) => {

    var date_init = new Date(item.date_init);
    var date_end = new Date(item.date_end);

    function datesAreOnSameDay(date_init, date_end){
      if( date_init.getFullYear() === date_end.getFullYear() &&
      date_init.getMonth() === date_end.getMonth() &&
      date_init.getDate() === date_end.getDate() ){
        return true;
      }
    }



      var eventDateInit = date_init.toLocaleDateString('en-US', {
    /*timeZone: 'America/Chicago',*/
    hour: '2-digit',
    minute: '2-digit',
  });
  var eventDateEnd = date_end.toLocaleDateString('en-US', {
/*timeZone: 'America/Chicago',*/
hour: '2-digit',
minute: '2-digit',
});

let pm = date_end.getHours() >= 12;
let hour12 = date_end.getHours() % 12;
if (!hour12)
  hour12 += 12;
let minute = ('0' + date_end.getMinutes() ).slice(-2);
var eventSameDateEnd = `${hour12}:${minute} ${pm ? 'PM' : 'AM'}`;

//var eventSameDateEnd = date_end.getHours() + ':' + date_end.getMinutes();
if(datesAreOnSameDay(date_init, date_end)){
  eventDateEnd = eventSameDateEnd;
}

    return (
      <div key={'d_' + item.id} className={'col-12 col-md-6 -event-row-is-' + item.isnext}>


      <a href={"/events/" + item.slug}>
       <ImageRenderer
          styleclass="img-blog"
          url={source_url + item.image_thumb['url']}
          width={item.image_thumb['width'] }
          height={item.image_thumb['height']}
          alt_tag={'Lilley Mansion ' +item.name}
          />
          <h3 className="ttl-blog text-center pt-3">{item.name}</h3>
          </a>
          <p className="text-center">
          {props.past === true ? <div><small><del>{eventDateInit}</del></small><div className="text-center badge m-2" style={{background:'#c3b8a0'}}>Past event</div></div>
          :
          <small>{eventDateInit} - {eventDateEnd}</small>
        }
          </p>
          <div className="text-center p-3">

          {/*item.isnext == 'next'  ?
          <a className="btn btn-light btn-secondary btn-small m-1" href={"https://discover.lilleymansion.com/cal/"+ item.id}><small>Save the date</small></a>
          : ''
        */}
        {item.isnext == 'next' && item.reservation_url ?
        <a className="btn btn-secondary btn-secondary_blue btn-small m-1" href={item.reservation_url}><small>Book this event</small></a>
        : ''
      }
          </div>

          {item.isnext == 'next'  ?
          <div className="excerpt-blog text-center mt-2 mb-4" dangerouslySetInnerHTML={{ __html: item.excerpt}}/>
          :
          ''
        }
          </div>

   );

 });
  //device = response.data.device;
  //newArr = 'xxxx'; //response.data.data.map(itemsStructure);

 setJsonContent(_jsonContent);

//return newArr;


  });
  }




  useEffect(() => {

    //console.log('loaded')
      loadJson(props.url);
  },[]);


  return (
    <div className="row">
      {jsonContent}
    </div>
  );
};

export default EventsList;
